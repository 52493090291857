@font-face {
    font-family: 'BR Candor';
    src: local('BR Candor Regular'), local('BR-Candor-Regular'),
        url('../fonts/BRCandor-Regular.woff2') format('woff2'),
        url('../fonts/BRCandor-Regular.woff') format('woff'),
        url('../fonts/BRCandor-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'BR Candor Light';
    src: local('BR Candor Light'), local('BR-Candor-Light'),
        url('../fonts/BRCandor-Light.woff2') format('woff2'),
        url('../fonts/BRCandor-Light.woff') format('woff'),
        url('../fonts/BRCandor-Light.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'BR Candor SemiBold';
    src: local('BR Candor SemiBold'), local('BR-Candor-SemiBold'),
        url('../fonts/BRCandor-SemiBold.woff2') format('woff2'),
        url('../fonts/BRCandor-SemiBold.woff') format('woff'),
        url('../fonts/BRCandor-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}
