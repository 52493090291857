.label {
    --color-red: var(--os-c-red);

    display: block;
    padding: 0 8px;
    margin: 0 0 4px;
    font-size: 0.85rem;

    &.invalid {
        color: var(--color-red);
    }
}
