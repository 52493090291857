table {
    width: 100%;
    border-radius: 3px;

    &.TableCustom,
    thead,
    tbody,
    tfoot,
    tr,
    th,
    td {
        height: auto;
        margin: 0;
        padding: 0;
        border: none;
        border-collapse: inherit;
        border-spacing: 0;
        border-color: inherit;
        vertical-align: inherit;
        text-align: left;
        font-weight: inherit;
        -webkit-border-horizontal-spacing: 0;
        -webkit-border-vertical-spacing: 0;
    }

    td,
    th {
        border: 1px solid var(--os-c-gray-light);
    }

    thead,
    .thead {
        td,
        th {
            background-color: var(--os-c-complement);
            font-weight: bold;
            font-size: 15px;
            text-align: center;
            padding: 1rem;
            color: var(--os-c-text);
            text-transform: uppercase;
        }
    }

    tbody,
    .tbody {
        td,
        th {
            font-size: 15px;
            padding: 1rem;
        }
    }

    .none {
        padding: 0 !important;
    }

    .item1,
    .item2,
    .item3,
    .item4 {
        width: 25%;
    }

    .itemcolspan3 {
        width: 75%;
    }

    .subitem1,
    .subitem2,
    .subitem3 {
        width: 33%;
    }

    .subitem2,
    .subitem3 {
        text-align: right;
    }
}
