.button {
    --font: var(--os-f-family);
    --breakpoint-sm: var(--os-media-sm);

    padding: 16px 16px;
    line-height: 1;
    height: 50px;
    display: inline-flex;
    align-items: center;
    font-size: 1rem;
    font-weight: bold;
    font-family: var(--font);
    cursor: pointer;
    // letter-spacing: 0.16px;
    border-radius: 3px;
    background-color: var(--os-c-primary);
    color: var(--os-c-light);
    transition: all 0.3s ease-in-out;
    border: 1px solid var(--os-c-primary);
    outline: none;
    text-decoration: none;
    align-items: center;
    justify-content: center;

    &:hover,
    &:visited {
        background-color: var(--os-c-primary-dark);
        color: var(--os-c-light);
        text-decoration: none;
    }

    &Full {
        width: 100%;
    }

    &Fit {
        width: 80%;
        margin: 0 auto;
        display: block;
    }

    &Auto {
        margin: 0 auto;
        display: inline-block;
        padding-left: 4rem;
        padding-right: 4rem;
    }

    &Secondary {
        background-color: var(--os-c-secondary);
        color: var(--os-c-light);
        border-color: var(--os-c-secondary);

        &:hover,
        &:visited {
            background-color: var(--os-c-secondary);
        }
    }

    &Complement {
        background-color: var(--os-c-complement);
        color: white;
        border-color: var(--os-c-complement);

        &:hover,
        &:visited {
            background-color: var(--os-c-complement);
        }
    }

    &Link {
        background-color: transparent;
        color: var(--os-c-primary-light);
        border-color: transparent;
        padding: 17px 0;
        cursor: pointer;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        text-decoration: underline;

        &:hover,
        &:visited {
            background-color: transparent;
            color: var(--os-c-primary-light);
            border-color: transparent;
            text-decoration: underline;
        }
    }

    &Excel {
        background-color: transparent;
        color: var(--os-c-excel);
        border-color: transparent;
        padding: 17px 0;
        cursor: pointer;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        text-decoration: none;

        &:hover,
        &:visited {
            background-color: transparent;
            color: var(--os-c-excel);
            border-color: transparent;
            text-decoration: underline;
        }
    }

    &Text {
        background-color: transparent;
        color: var(--os-c-text);
        border-color: transparent;
        padding: 17px 0;
        cursor: pointer;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        text-decoration: none;

        &:hover,
        &:visited {
            background-color: transparent;
            color: var(--os-c-text);
            border-color: transparent;
            text-decoration: underline;
        }

        &Underline {
            text-decoration: underline;
        }
    }

    &Outline {
        background-color: transparent;
        color: var(--os-c-complement);
        border-color: var(--os-c-complement);

        &:hover,
        &:visited {
            color: white;
            background-color: var(--os-c-complement);
        }
    }

    &IconLeft {
        margin-right: 10px;
    }

    &IconRight {
        margin-left: 10px;
    }

    &[disabled] {
        background-color: var(--os-c-disabled);
        border-color: var(--os-c-disabled);
        color: var(--os-c-disabled-text);
        cursor: default;
    }

    @media (max-width: #{--breakpoint-sm}) {
        &Fit {
            width: 100%;
        }
    }
}
