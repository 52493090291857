:root {
    --os-f-family: 'Lato', sans-serif;
    --os-f-family-alt: 'Lato', sans-serif;

    --os-c-primary: #d50032;
    --os-c-primary-dark: #b1022b;
    --os-c-primary-light: #5186c5;
    --os-c-primary-light-2: #f5f5fa;

    --os-c-secondary: #5c5b5b;
    --os-c-secondary-light: #7e7e7d;

    --os-c-complement: #f7f4f2;
    --os-c-complement-dark: #adadad;

    --os-c-excel: #05733b;

    --os-c-light: #fff;

    --os-c-text: #515151;
    --os-c-text-menu: #9c9c9c;
    --os-c-text-help: #b4b4b4;

    --os-c-table-zebra: #ebeef6;

    --os-c-gray: #b7b7b7;
    --os-c-gray-accent: #8b8b8b;
    --os-c-gray-light: #f2f2f2;
    --os-c-gray-dark: #c8c6c4;

    --os-c-red: #b1022b;
    --os-c-success: #05733b;

    --os-c-red-dark: #cc0000;

    --os-c-red-light: #{rgba(#cc0000, 0.15)};

    --os-c-disabled: #ebebeb;
    --os-c-disabled-text: #fff;

    --os-breakpoint-sm: 480px;
    --os-breakpoint-md: 540px;
    --os-breakpoint-lg: 960px;
    --os-breakpoint-xl: 1240px;
    --os-breakpoint-xxl: 1300px;
}
