.Popover {
    &Items {
        font-size: 21px;
        line-height: 27px;
        font-weight: normal;
        font-family: var(--font-light);

        &Name {
            font-size: 1.25rem;
            font-weight: 400;
        }

        &Menu {
            margin-left: 1rem;
            display: flex;
            align-items: center;
            position: relative;
            height: 40px;
        }
    }

    &ContentIcon {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-left: 5px;
        height: 40px;

        span {
            display: inline-flex;
        }
    }

    &Avatar {
        margin-left: 5px;
    }

    &Icon {
        margin-left: 5px;

        img {
            width: 1rem;
        }
    }

    &Ul {
        background: #fff;
        border: 1px solid var(--os-c-gray);
        box-sizing: border-box;
        box-shadow: 1px 0px 12px rgba(0, 0, 0, 0.08);
        border-radius: 10px;
        outline: none;
        position: absolute;
        right: 0;
        margin-top: 160px !important;
        width: 230px;
        min-height: 2rem;
        z-index: 3;

        &::before {
            content: '';
            position: absolute;
            right: 10px;
            top: -9px;
            width: 16px;
            height: 16px;
            background-color: white;
            transform: rotate(45deg);
            border-left: 1px solid var(--os-c-gray);
            border-top: 1px solid var(--os-c-gray);
        }
    }

    &Li {
        display: block;
        color: var(--os-c-text);
        font-size: 1.25rem;
        padding: 1rem 1.25rem;
        line-height: 1;

        a,
        > span {
            display: flex;
            align-items: center;
            box-sizing: border-box;
            width: 100%;
            color: var(--os-c-primary);
            font-family: var(--os-f-family);
            cursor: pointer;

            &.active {
                color: var(--os-c-primary);
                text-decoration: underline;
            }

            svg {
                margin-right: 10px;
            }
        }

        &Name {
            text-align: center;
            font-size: 1.25rem;
            font-weight: normal;
            padding: 0.75rem 1.25rem 0.75rem;
            border-bottom: 1px solid var(--os-c-gray);

            a,
            > span {
                cursor: default;
            }
        }

        &Highlight {
            color: var(--os-c-secondary);

            a,
            > span {
                color: var(--os-c-secondary);

                &.active {
                    color: var(--os-c-secondary);
                }
            }
        }
    }

    // animated
    &Enter {
        transition: 100ms ease-out;
    }

    &EnterFrom {
        transform: scale(0.75);
        opacity: 0;
    }

    &EnterTo {
        transform: scale(1);
        opacity: 1;
    }

    &Leave {
        transition: 75ms ease-out;
    }

    &LeaveFrom {
        transform: scale(1);
        opacity: 1;
    }

    &LeaveTo {
        transform: scale(0.75);
        opacity: 0;
    }

    @media (max-width: 1240px) {
        &ContentIcon {
            margin-left: 0;
        }

        &Icon {
            margin-left: 2px;
        }

        &Avatar {
            margin-left: 2px;
        }

        &Items {
            &Menu {
                margin-left: 0.25rem;
            }
        }
    }
}
