.Content {
    margin: 0;
    box-sizing: border-box;
    min-height: 200px;
    height: calc(95vh - 86px);
    overflow-y: auto;
    overflow-x: hidden;

    Header {
        height: 86px;
    }

    &Full {
        height: 100vh;
    }

    Footer {
        height: 5vh;
    }

    &Inner {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 1rem 2.5rem 2.5rem;
    }

    @media (max-width: 1240px) {
        &Inner {
            padding: 1rem 1.5rem 1.5rem;
        }
    }

    @media (max-width: 960px) {
        &Inner {
            padding: 1rem;
        }
    }
}
