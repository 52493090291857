$breakpoint-sm: #{var(--os-breakpoint-sm)};

.Login {
    --breakpoint-sm: var(--os-breakpoint-sm);

    &Wrap {
        width: 480px;
        height: 100vh;
        box-sizing: border-box;
        margin: 0 auto;
        display: flex;
        align-content: center;
        justify-content: center;

        &Form {
            width: 320px;
            margin: 0 auto;
        }

        &Inner {
            align-self: center;
        }

        &Full {
            display: flex;
            width: 100%;
            height: 95vh;
            box-sizing: border-box;
            background-color: white;
        }

        &Foot {
            display: flex;
            width: 100%;
            height: 5vh;
            box-sizing: border-box;
            background-color: white;
            align-items: center;
            justify-content: start;
            padding-left: 7.5rem;
            font-weight: normal;
            font-size: 0.625rem;
            line-height: 0.75rem;
            border-top: 0.5px solid var(--os-c-gray);
        }

        &Logo {
            margin: 0 0 1.5rem;
            text-align: center;

            img {
                max-width: 100%;
                width: 320px;
                display: inline-block;
                margin-bottom: 1rem;
            }

            p {
                text-align: center;
                margin: 0;
                font-weight: 600;
                font-size: 1rem;
                line-height: 19px;
            }
        }
    }

    &Banner {
        flex-basis: 55%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
        position: relative;

        &Right {
            position: absolute;
            right: 0;
            top: 0;
        }

        &Left {
            position: absolute;
            left: 0;
            bottom: 0;
        }
    }

    @media (max-width: 1300px) {
        &Wrap {
            &Full {
                padding-left: 0;
                background-image: none !important;
                background-color: transparent;
            }

            .buttonFit {
                width: 100%;
            }

            &Foot {
                justify-content: center;
                padding: 0 1rem;
            }
        }

        &Banner {
            display: none;
        }
    }

    @media (max-width: 540px) {
        &Wrap {
            width: 96%;

            &Form {
                width: 96%;
            }
        }
    }
}
