.mb1 {
    margin-bottom: 1rem;
}

.mb2 {
    margin-bottom: 2rem;
}

.mbm {
    margin-bottom: 0.5rem;
}

.mbsm {
    margin-bottom: 0.25rem;
}

.small {
    font-size: 0.75rem;
}

.medium {
    font-size: 0.9rem;
}

.big {
    font-size: 1.25rem;
    line-height: 26px;
}

.fweight {
    font-weight: bold;
}

.separate {
    flex: 1;
}

.thelp {
    color: var(--os-c-text-help);
}

.noData {
    text-align: center;
    font-size: 1.25rem;
    padding: 2rem 0.5rem;
}

.msgError {
    text-align: center;
    font-size: 1.25rem;
    padding: 2rem 0.5rem;
    color: var(--os-c-red);
}

.t-right {
    text-align: right !important ;
}
